<template>
    <LoadingViewTransition :loading="loadingView" :error-box="errorBox">
        <form class="st-view" @submit.prevent="$emit('save')">
            <STNavigationBar :title="title" :disable-pop="true" :disable-dismiss="true">
                <template v-if="canPop || (!preferLargeButton && ($isMobile || $isIOS || $isAndroid))" #left>
                    <BackButton v-if="canPop" @click="pop" />
                    <button v-else-if="$isAndroid" class="button navigation icon close" type="button" @click="pop" />
                    <button v-else class="button text selected unbold" type="button" @click="pop">
                        {{ cancelText }}
                    </button>
                </template>

                <template #right>
                    <template v-if="!$isMobile && !$isIOS">
                        <slot name="buttons" />
                    </template>
                    <LoadingButton v-if="canDelete" :loading="deleting">
                        <button class="button icon trash navigation" type="button" :disabled="deleting" @click="$emit('delete')" />
                    </LoadingButton>
                    <LoadingButton v-if="!preferLargeButton && ($isMobile || $isIOS || $isAndroid)" :loading="loading">
                        <button class="button navigation highlight" :disabled="disabled" type="submit">
                            {{ saveText }}
                        </button>
                    </LoadingButton>
                    <template v-else-if="canDismiss && !(!preferLargeButton && ($isMobile || $isIOS || $isAndroid))">
                        <button v-if="!$isIOS" class="button navigation icon close" type="button" @click="dismiss" />
                        <button v-else class="button text selected unbold" type="button" @click="dismiss">
                            {{ cancelText }}
                        </button>
                    </template>
                </template>
            </STNavigationBar>
            <main class="center" :class="mainClass">
                <slot />
            </main>
            <STToolbar v-if="preferLargeButton || (!$isMobile && !$isIOS && !$isAndroid)">
                <template #left>
                    <slot name="left" />
                </template>
                <template #right>
                    <div v-if="$slots.toolbar" class="editor-button-bar">
                        <slot name="toolbar" />
                    </div>
                    <button v-else-if="!$slots.toolbar && addExtraCancel && (canPop || canDismiss) && cancelText !== null" class="button secundary" type="button" @click="pop">
                        {{ cancelText }}
                    </button>
                    <LoadingButton :loading="loading">
                        <button class="button" :class="saveButtonClass" :disabled="disabled" type="submit">
                            <span v-if="saveIcon" class="icon " :class="saveIcon" />
                            <span>{{ saveText }}</span>
                            <span v-if="saveIconRight" class="icon " :class="saveIconRight" />
                            <span v-if="saveBadge" class="bubble" v-text="saveBadge" />
                        </button>
                    </LoadingButton>
                </template>
            </STToolbar>
            <STButtonToolbar v-else-if="!!$slots.buttons || !!$slots.toolbar" class="sticky">
                <slot name="buttons" />
                <slot name="toolbar" />
            </STButtonToolbar>
        </form>
    </LoadingViewTransition>
</template>

<script lang="ts" setup>
import { useCanDismiss, useCanPop, useDismiss, usePop } from '@simonbackx/vue-app-navigation';
import { computed, getCurrentInstance } from 'vue';
import LoadingViewTransition from '../containers/LoadingViewTransition.vue';
import { ErrorBox } from '../errors/ErrorBox';
import BackButton from './BackButton.vue';
import LoadingButton from './LoadingButton.vue';
import STButtonToolbar from './STButtonToolbar.vue';
import STNavigationBar from './STNavigationBar.vue';
import STToolbar from './STToolbar.vue';
import { useKeyDown } from '../hooks';

withDefaults(
    defineProps<{
        loading?: boolean;
        loadingView?: boolean;
        errorBox?: ErrorBox | null;
        deleting?: boolean;
        disabled?: boolean;
        title?: string;
        saveText?: string;
        saveIcon?: string | null;
        saveButtonClass?: string | null;
        saveIconRight?: string | null;
        saveBadge?: string | null;
        cancelText?: string | null;
        preferLargeButton?: boolean;
        addExtraCancel?: boolean;
        mainClass?: string;
    }>(), {
        loading: false,
        loadingView: false,
        errorBox: null,
        deleting: false,
        disabled: false,
        title: '',
        saveText: 'Opslaan',
        saveIcon: null,
        saveButtonClass: 'primary',
        saveIconRight: null,
        saveBadge: null,
        cancelText: 'Annuleren',
        preferLargeButton: false,
        addExtraCancel: false,
        mainClass: '',
    },
);

const canDelete = computed(() => {
    // Check has delete listener
    return !!getCurrentInstance()?.vnode.props?.onDelete;
});

const canDismiss = useCanDismiss();
const canPop = useCanPop();
const dismiss = useDismiss();
const pop = usePop();
const emit = defineEmits(['save', 'delete']);

// CMD + S = Save
useKeyDown((key, modifiers) => {
    if (key === 's' && (modifiers.ctrl || modifiers.meta)) {
        void emit('save');
        return true;
    }
    return false;
});
</script>
